<template>
  <div
    class="Button"
    :class="{ disabled }"
    @click="!disabled && $emit('click')">
    <span class="label">{{ label }}</span>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped lang="scss">
.Button {
    display: inline-block;
    background-color: $standardButtonColor;
    color: white;
    padding: 0.3em 0.7em;
    border-radius: 0.2em;
    cursor: pointer;

    &.disabled {
        cursor: default;
        background-color: lightgray;
    }
}
.label {
    font-size: 90%;
}
</style>
